import React, { useEffect } from "react";
// import "./updateEmployee.css";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { counterSchema } from ".././../../validations";
import "./addcounter.css";
import { useUpdateCounter } from "../../../hooks/counter";
import { useStateContext } from "../../../utls/Context";

const UpdateCounter = ({ items,isOpen, onClose, id, onUpdateSuccess }) => {
  const navigate = useNavigate();
  const {logout}= useStateContext()

  const { mutateAsync, isLoading, isSuccess, isError, data, error } =
    useUpdateCounter();

  const {
    resetForm,
    values,
    handleBlur,
    errors,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: items,
    validationSchema: counterSchema,
    onSubmit: async (values) => {
      try {
        await mutateAsync({ id, values });

        toast.success("Counter Updated successfully!");
        onUpdateSuccess();
        onClose();
      } catch (error) {
        console.error("Error during login:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const { status } = data;

      if (status === 200) {
        resetForm();
      }
    }

    if (isError) {
      toast.error("Something went wrong");
      if (error?.response?.data?.status === 401) {
        navigate("/");
        logout();
      }
    }
  }, [isError, isSuccess, data, error, resetForm, navigate, logout]);

  return (
    //   <div className="modal-overlay" onClick={onClose}>
    //   <div className="modal-content" onClick={(e) => e.stopPropagation()}>

    <div className="addemployeewrapper">
      <div className="loginformwraper" onClick={(e) => e.stopPropagation()}>
        <button
          type="button"
          className="cancelbtt"
          onClick={onClose}
          style={{
            background: "none",
            border: "none",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <i
            className="fa-solid fa-xmark fa-xl"
            style={{ color: "var(--secondary-text-color)", cursor: "pointer" }}
          ></i>
        </button>
        <h2 className="title">Update Counter</h2>

        <form onSubmit={handleSubmit}>
            <div className="fileinput">
                <label>Counter Name</label>
              <div className="inputtype">
                <input
                  type="text"
                  placeholder="Enter Counter Name"
                  name="counterName"
                  value={values.counterName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.counterName && touched.counterName && (
                  <div className="error-message">{errors.counterName}</div>
                )}
              </div>
            </div>

            <div className="fileinput">
              
                <label>Counter Type</label>
               
              <div className="inputtype">
                <select
                  name="counterType"
                  value={values.counterType}
                  onChange={handleChange}
                >
                  <option value="">Select Counter Type</option>
                  <option value="ticketcollector">ticketcollector</option>
                  <option value="nonticketcollector">nonticketcollector</option>
                </select>

                {errors.counterType && touched.counterType && (
                  <div className="error-message">{errors.counterType}</div>
                )}
              </div>
            </div>

            <div className="fileinput">
                <label>Rista Branch Name</label>
                
              <div className="inputtype">
                <input
                  type="text"
                  placeholder="Enter Rista Branch Name"
                  name="ristaBranchName"
                  value={values.ristaBranchName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.ristaBranchName && touched.ristaBranchName && (
                  <div className="error-message">{errors.ristaBranchName}</div>
                )}
              </div>
            </div>

            <div className="fileinput">
                <label>Rista Cashier</label>
                
              <div className="inputtype">
                <input
                  type="text"
                  placeholder="Enter Rista Cashier"
                  name="ristaCashier"
                  value={values.ristaCashier}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.ristaCashier && touched.ristaCashier && (
                  <div className="error-message">{errors.ristaCashier}</div>
                )}
              </div>
            </div>

            <div className="fileinput">
              
                <label>UPI Name</label>
                
              <div className="inputtype">
                <input
                  type="text"
                  placeholder="Enter UPI Name"
                  name="upiName"
                  value={values.upiName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.upiName && touched.upiName && (
                  <div className="error-message">{errors.upiName}</div>
                )}
              </div>
            </div>

            <div className="button">
              <button type="submit" className="btt"
                          disabled={isLoading} 
>
                {isLoading ? (
                  <BeatLoader size={8} color={"#ffffff"} />
                ) : (
                  "Submit Now"
                )}
              </button>
            </div>
          </form>
      </div>
    </div>

    //   </div>
    // </div>
  );
};
export default UpdateCounter
