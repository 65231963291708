import React from "react";
import axiosInstance from "../../../services/axiosInstance";
import ENDPOINTS from "../../../services/endPoints";
import { toast } from "react-toastify";

const CounterItem = ({ items, onDeleteSuccess,onEditClick }) => {
  console.log("🚀 ~ CounterItem ~ items:", items)
  
  const handleDelete = async (itemId) => {
    try {
      const { data } = await axiosInstance({
        method: "delete",
        url: `${ENDPOINTS.DELETE_COUNTER}${itemId}`, // Corrected the URL interpolation
        headers: { "Content-Type": "application/json" },
      });
      toast.success("Counter item deleted successfully");
      onDeleteSuccess();
      return data;
    } catch (error) {
      console.error("Error deleting counter item:", error);

      throw error; // Rethrow the error for error handling in higher components if needed
    }
  };

  const confirmAndDelete = (itemId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this counter?");
    if (isConfirmed) {
      handleDelete(itemId);
    }
  };

  return (
    <div>
      <div className="counteritemwrapper">
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
      <i
            className="fa-solid fa-file-pen"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            // onClick={() => {
            //   handleEdit(items?._id);
            // }}
            onClick={() => onEditClick(items)} // Trigger edit click handler
          ></i>
        <i
          className="fa-solid fa-trash"
          style={{ display: "flex", justifyContent: "flex-end",cursor:'pointer' }}
          onClick={() => {
            confirmAndDelete(items?._id);
          }}
        ></i>
        </div>
        <div className="counteritemcontent">
          <p>
            Counter Name: <span>{items?.counterName}</span>
          </p>
          <p>
            Counter Type: <span>{items?.counterType}</span>
          </p>
          <p>
            Rista Branch Name: <span>{items?.ristaBranchName}</span>
          </p>
          <p>
            Rista Cashier: <span>{items?.ristaCashier}</span>
          </p>
          <p>
            UPI Name: <span>{items?.upiName}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CounterItem;
