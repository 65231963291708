import React, { useEffect, useState } from "react";
import CounterItem from "./CounterItem";
import './addcounter.css'

import { useCounterName } from "../../../hooks/counter";
import { useNavigate } from "react-router-dom";
import UpdateCounter from "./UpdateCounter";

const CounterDetails = () => {
  const navigate = useNavigate();
  const [reloadData, setReloadData] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCounter, setSelectedCounter] = useState(null);
  const { data: counterdata, refetch } = useCounterName();

  const handleCounter = () => {
    navigate("/counterdetails/addcounter");
  };

  useEffect(() => {
    if (reloadData) {
      
      refetch();
      setReloadData(false);
    }
  }, [reloadData, refetch]);
  return (
    <div>
      <div className="uploaddetails">

        <div className="uploadwrapper">
          <div className="contercontent">
            <div className="heading">
              <p>Counter:-</p>
            </div>
            <div className="counterbtt">
              <button className="btt" onClick={handleCounter}>
                Add Counter
              </button>
            </div>
          </div>

          <div className="counteritem">
            {counterdata?.data?.data?.map((item, index) => {
              return (
                <div className="colcounter" key={index}>
                  <CounterItem
                    items={item}
                    onDeleteSuccess={() => setReloadData(true)}
                    onEditClick={(counter) => {
                      setSelectedCounter(counter);
                      setModalOpen(true);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isModalOpen && selectedCounter && (
        <UpdateCounter
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          id={selectedCounter?._id}
          items={selectedCounter}
          onUpdateSuccess={() => {
            setModalOpen(false);
            setReloadData(true); // Trigger refetch on success

            // Optionally trigger a refetch or update parent state
          }}
        />
      )}
    </div>
  );
};

export default CounterDetails;
